const authenticate = {   
    mounted() {
        if (!this.canApproveCurrentWorkflowRequest) {
            this.$router.push({
                name: "error",
                params: {
                    message: "UnAuthorized"
                },
            });
        } else {
            this.$store
                .dispatch("wiretransfer/GetRequestWithTaskDetails", parseInt(this.id))
                .then((res) => {
                    this.Request = res.data;
                    if (this.Request.WireTransferRequestWorkflowDetail != null) {
                        this.Request.WireTransferRequestWorkflowDetail.Comments = "";
                    }           
                })
                .catch((err) => {
                    if (err.response && err.response.status == 403) {
                        this.$router.push({
                            name: "error",
                            params: {
                                message: "UnAuthorized"
                            },
                        });
                    }
                    else {
                        this.Error = true;
                        alert(err);
                    }
                });
        }
    }
};
export default authenticate;